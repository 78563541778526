<template>
    <div>
        <Header :title="$t('turkmer_page_show_title')"></Header>
        <b-card class="main-content">
            <template #header>
                <div class="d-flex flex-row align-items-center">
                    <h5 class="mb-0">
                        {{ applicationForm.name }} {{ applicationForm.surname }}
                    </h5>
                </div>
            </template>
            <b-card-text>
                <b-row>
                    <b-col cols="12" class="mb-4 mt-2">
                        <h5 class="mb-0">{{ $t("personal_info") }}</h5>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('passport_number')">
                            <div class="label-as-input">
                                {{
                                    getObjectValue(applicationForm, "passport_number")
                                }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('tc_no')">
                            <div class="label-as-input">
                                {{
                                    getObjectValue(applicationForm, 'national_id') !== 'null' ? getObjectValue(applicationForm, 'national_id') : '-'
                                }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('gender')">
                            <div class="label-as-input">
                                {{
                                    getObjectValue(applicationForm, "gender")
                                }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('date_of_birth')">
                            <div class="label-as-input">
                                {{
                                    toLocaleDate(applicationForm.birthdate)
                                }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('nationality')">
                            <div class="label-as-input">
                                {{
                                    getObjectValue(applicationForm, "nationality_code")
                                }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('email')">
                            <div class="label-as-input">
                                {{ getObjectValue(applicationForm, "email") }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('city')">
                            <city-selectbox v-model="applicationForm.city_id" disabled/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('district')">
                            <district-outline-selectbox v-model="applicationForm.district_id"
                                                        :city_id="applicationForm.city_id"
                                                        disabled/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('address')">
                            <div class="label-as-input">
                                {{ getObjectValue(applicationForm, "address") }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('gsm_no')">
                            <div class="label-as-input">
                                {{ getObjectValue(applicationForm, "mobile_tel") }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('emergency_person')">
                            <div class="label-as-input">
                                {{ getObjectValue(applicationForm, "emergency_person") }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('emergency_person_phone')">
                            <div class="label-as-input">
                                {{ getObjectValue(applicationForm, "emergency_person_number") }}
                            </div>
                        </b-form-group>
                    </b-col>

                </b-row>
                <b-row>
                    <b-col cols="12" class="mb-4 mt-2">
                        <h5 class="mb-0">{{ $t("program_select") }}</h5>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('course_exam_type')">
                            <div class="label-as-input">
                                {{ getObjectValue(applicationForm, "program_type") }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('program')">
                            <div class="label-as-input">
                                {{
                                    getObjectValue(applicationForm, "program_code")
                                }}-{{ getObjectValue(applicationForm, "program_name") }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" lg="4">
                        <b-form-group :label="$t('language_text')">
                            <div class="label-as-input">
                                {{ getApproveText(applicationForm.before_turkish_certificate_status) }}
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="mb-4 mt-2">
                        <h5 class="mb-0">{{ $t("approval_status") }}</h5>
                    </b-col>
                    <b-col md="4">
                        <b-form-group>
                            <div class="label-as-input">
                                {{ getTurkmerApplicationStatusText(applicationForm.status) }}
                            </div>
                        </b-form-group>
                    </b-col>
                    <b-col v-if="applicationForm.status === 'denied' && applicationForm.reason" md="4">
                        <b-form-group>
                            <div class="label-as-input">
                                {{ getObjectValue(applicationForm, "reason") }}
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="mb-4 mt-2">
                        <h5 class="mb-0">{{ $t("documents") }}</h5>
                    </b-col>
                    <b-col cols="12">
                        <div class="d-flex flex-wrap">
                            <b-card :sub-title="index === 'image' ? $t('image') : $t('before_turkish_certificate')"
                                    class="mr-2"
                                    style="min-width: 18rem;"
                                    v-for="(doc, index) in applicationForm.documents" :key="index">
                                <b-card-text>{{ $t('status') }}: {{ $t(`status_${doc.status}`) }}</b-card-text>
                                <template #footer>
                                    <div class="d-flex flex-row justify-content-around">
                                        <a class="card-link" @click="showDoc(doc,index)">{{ $t('download') }}</a>
                                    </div>
                                </template>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>
<script>
// Template
import Header from "@/modules/turkmerApplicationForm/layout/Header.vue";

// Components
import CitySelectbox from "@/components/interactive-fields/CitySelectbox.vue";
import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox.vue";
// Service
import TurkmerApplicationService from "@/services/TurkmerApplicationService";
// Other
import qs from "qs";

export default {
    props: {
        formId: {
            type: Number,
        },
        pin: {
            type: String,
        }
    },
    components: {
        Header,
        DistrictOutlineSelectbox,
        CitySelectbox,
    },
    metaInfo() {
        return {
            title: this.$t("turkmer_page_show_title"),
        };
    },
    data() {
        return {
            isNewButton: false,
            applicationForm: {},
            loading: false,
            courseCodeOptions: [
                {text: this.$t('course_code_option'), value: 'Course'},
                {text: this.$t('exam'), value: 'Exam'},
            ],
            programs: []
        };
    },
    async created() {
        this.getData(this.formId)
    },
    methods: {
        getData(id) {
            const config = {
                params: {
                    pin: this.pin,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            TurkmerApplicationService.showUser(id, config)
                .then(response => {
                    this.applicationForm = response.data.data;
                }).catch(err => {
                this.showErrors(err)
            })
        },
        async showDoc(doc, type) {
            const config = {
                params: {
                    type: type,
                    turkmer_application_id: this.applicationForm.id,
                    file: doc,
                    pin: this.pin,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };
            config.responseType = 'arraybuffer';

            TurkmerApplicationService.downloadDocuments(config)
                .then(response => {
                    this._downloadFile(response, doc.filename)
                }).catch(err => {
                this.showErrors(err)
            })
        },
    },
};
</script>
<style>
.main-content {
    padding: 115px 50px;
}
</style>
