<template>
    <div>
        <component :is="currentComponent" v-bind="componentProps"/>
    </div>
</template>
<script>
import ApplicationFormPreview from "@/modules/turkmerApplicationForm/pages/ApplicationFormPreview.vue";
import ApplicationFormSend from "@/modules/turkmerApplicationForm/pages/ApplicationFormSend.vue";

export default {
    components: {
        ApplicationFormPreview,
        ApplicationFormSend,
    },
    data() {
        return {
            formData: {},
        }
    },
    async mounted() {
        this.$store.dispatch('turkmerApplication/isLogin');
        let data = this.$store.getters['turkmerApplication/getData'];
        if (data) {
            this.formData = data
        }
    },
    computed: {
        currentComponent() {
            return this.formData.status === 'waiting_preference' || !this.formData.status
                ? 'ApplicationFormSend'
                : 'ApplicationFormPreview';
        },
        componentProps() {
            if (!this.formData || !this.formData.status) return {};

            return this.currentComponent === 'ApplicationFormSend'
                ? { formData: this.formData }
                : { formId: this.formData.id, pin: this.formData.pin };
        },
    },
    methods: {
        updateTab(newIndex) {
            this.tabIndex = newIndex;
        }
    },
}
</script>
