<template>
    <header class="header fixed-top">
        <b-navbar class="p-0">
            <b-navbar-brand class="d-flex align-items-center justify-content-center collapsed-logo desktop-logo">
                <div class="logo-wrapper">
                    <router-link to="/dashboard">
                        <img v-show="$store.getters['dashboard/getImage']('menu-logo.png')"
                             :src="$store.getters['dashboard/getImage']('menu-logo.png')"/>
                    </router-link>
                </div>
            </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav>
                <b-collapse id="nav-collapse" is-nav class="d-block">
                    <b-navbar-nav class="ml-0 ml-lg-auto pl-4 pl-lg-0 py-4 pr-4 py-lg-5 pr-lg-5">
                        <div class="d-flex">
<!--                            <b-button class="btn width-80 mr-2" :variant="timerBtnVariant"
                                      :title="$t('session_timer_title')"
                                      @click="callTokenClick">
                                <i class="ri-timer-line"></i> {{ timerOutput }}
                            </b-button>-->

                            <b-button class="btn p-1 text-danger"
                                      @click="$router.push('/turkmer/logout')"
                                      variant="outline-secondary mr-2">
                                {{ $t('logout') }}
                            </b-button>
                        </div>
                    </b-navbar-nav>
                </b-collapse>
            </b-collapse>
        </b-navbar>
    </header>
</template>
<script>

// Other
import {mapGetters} from "vuex"
import Base from "@/plugins/Base"
import {EventBus} from "@/main"


export default {
    props: {
        title: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            countDownToTime: Base.LocalStorage.get('expire_in') * 1000,
            timerOutput: null,
            timerBtnVariant: 'outline-secondary',
            timerDangerMin: 15 * 60
        }
    },
    watch: {
        timerOutput: {
            handler(value) {
                let splitData = value.split(':')
                let timeInSec = (parseInt(splitData[0]) * 60) + parseInt(splitData[1])
                if (timeInSec < this.timerDangerMin) {
                    this.timerBtnVariant = 'outline-danger'
                } else {
                    this.timerBtnVariant = 'outline-secondary'
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            twoFactor: "user/getTwoFactor"
        }),

        // User
        user() {
            return this.$store.getters['auth/getUser'];
        },

        // Photo
        photo() {
            let photo = this.$store.getters['auth/getPhoto'];
            if (photo) {
                return '\'' + photo + '\'';
            }
            return null;
        },
    },
   /* methods: {
        startTimer: function () {
            const timeNow = new Date().getTime();
            const timeDifference = this.countDownToTime - timeNow;
            const millisecondsInOneSecond = 1000;
            const millisecondsInOneMinute = millisecondsInOneSecond * 60;
            const millisecondsInOneHour = millisecondsInOneMinute * 60;
            const remainderDifferenceInMinutes = (timeDifference % millisecondsInOneHour) / millisecondsInOneMinute;
            const remainderDifferenceInSeconds = (timeDifference % millisecondsInOneMinute) / millisecondsInOneSecond;
            const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
            const remainingSeconds = Math.floor(remainderDifferenceInSeconds);
            const nullSec = remainingSeconds < 10 ? '0' : ''
            const nullMin = remainingMinutes < 10 ? '0' : ''
            if (remainingMinutes + remainingSeconds > 0) {
                this.timerOutput = nullMin + remainingMinutes + ":" + nullSec + remainingSeconds;
            } else {
                this.timerOutput = '00:00'
            }
        },
        updateCountDown: function () {
            this.countDownToTime = Base.LocalStorage.get('expire_in') * 1000;
        },

        callTokenClick() {
            if (this.timerBtnVariant == 'outline-danger') {
                EventBus.$emit("captchaModalShow", true)
            }
        }
    },
    mounted() {
        setInterval(() => {
            this.startTimer()
        }, 1000);
        setInterval(() => {
            this.updateCountDown()
        }, 30000);
    },*/
}
</script>
